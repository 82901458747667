import * as React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {debounce} from 'lodash';
import AgreementList from '../components/AgreementList';
import Search from '../components/Search';
import API from '../services/API';
import Transformers from '../services/Transformers';
import AgreementVersion from '../types/AgreementVersion';
import Loader from '../components/Loader';
import APIAgreementsResponse from '../types/APIAgreementsResponse';

interface HomePageProps extends RouteComponentProps {}
interface HomePageState {
	loading: boolean,
	query: string,
	results: AgreementVersion[]
	error: Error | null
}

class HomePage extends React.Component<HomePageProps, HomePageState> {
	constructor(props: HomePageProps) {
		super(props);
		this.state = {
			loading: true,
			query: new URLSearchParams(this.props.location.search).get('query') || '',
			results: [],
			error: null
		};
	}

	componentDidMount() {
		this.onSearch(this.state.query);
	}

	onSearch = (query: string) => {
		this.setState({
			query,
			loading: true,
			results: [],
			error: null
		});
		this.props.history.push({
			pathname: this.props.location.pathname,
			search: `?query=${encodeURIComponent(query)}`
		});

		this.doSearch(query);
	};

	doSearch = debounce(async (query: string) => {
		this.setState({
			loading: true,
			results: [],
			error: null
		});

		try {
			let response: APIAgreementsResponse|null = null;
			if(query === "") {
				response = await API.getLastUpdated();
			} else {
				response = await API.search(query);
			}

			const results = Transformers.agreementVersionsFromAgreementsResponse(response);
			this.setState({
				results,
				loading: false
			});
		} catch (error) {
			console.error(error);
			this.setState({
				loading: false,
				error
			});
		}
	}, 1000);

	render() {
		let content: React.ReactNode = null;

		if(this.state.loading) {
			content = <Loader text="Loading results..." />;
		} else if(this.state.error) {
			content = <div className="alert alert-danger">
				<strong>Oida!</strong> Noe gikk galt idet vi forsøkte gjennomføre søket ditt. Noen er på saken om dette skulle vise seg å være et langvarig problem, men <a href="#" onClick={() => this.onSearch(this.state.query)}>prøv gjerne søket igjen</a>.
			</div>
		} else if (this.state.results.length) {
			content = <>
				{this.state.query === "" && <>
					<h2 className="mb-4">Sist oppdaterte avtaler</h2>
				</>}
				<AgreementList agreements={this.state.results}/>
			</>
		} else if(this.state.query != "") {
			content = <p>Vi fant dessverre ingen avtaler som samsvarer med søket ditt...</p>
		}

		return (
			<div className="container-narrow container-pad-both-sides">
				<h1 className="text-center mt-14">Finn avtaler</h1>
				<Search query={this.state.query} onChange={this.onSearch}/>
				{content}
			</div>
		);
	}
}

export default HomePage;

import * as React from "react";
import API from "../services/API";

export enum DownloadFileLinkType {
    Addendum = "addendum",
    Agreement = "agreement",
    Attachment = "attachment",
    Protocol = "protocol",
}

interface IDownloadFileLinkProps {
    href: string;
    type: DownloadFileLinkType;
    uuid: string;
};

const DownloadFileLink: React.FC<IDownloadFileLinkProps> = ({
    href,
    type,
    uuid
}): JSX.Element => {
    const onMouseDown = async (event: any) => {
        // Not ready for test/production yet
        // if (!event) {
        //     return;
        // }

        // if (event.button === 0 || event.button === 1) { // 0 = left mouse button & 1 = middle mouse button
        //     // This solution is not perfect as the counter only react upon going to the website first.
        //     // Copying and using the link directly or using right click menu, won't result in a count.
        //     await API.countDownload({
        //         type: type,
        //         uuid: uuid
        //     });
        // }

		  const origin=event.currentTarget;

		  const href=origin.getAttribute("href");

		  if (href.substr(0,36)=='https://avtale.fagforbundet.no/files') {
				const filename = href.substr(37);
				const url='https://www1.fagforbundet.no/track_agreement/?file=' + filename + '&origin=avtale.fagforbundet.no';

				 var xhrobj = new XMLHttpRequest();
				 xhrobj.open('GET',url);
				 xhrobj.send();
		  }

    }

    return (
        <>
            <a className="mx-3 text-nowrap" href={href} onMouseDown={onMouseDown}>
                Last ned
                <i className="ffnext-link-arrow-down ml-2" />
            </a>
        </>
    )
};

export default DownloadFileLink;

import * as React from 'react';

interface LoaderProps {
	text: string
}
interface LoaderState {}

class Loader extends React.Component<LoaderProps, LoaderState> {
	render() {
		return (
			<div className="d-flex justify-content-center">
				<div className="spinner-border text-primary" role="status" style={{
					width: '3rem',
					height: '3rem'
				}}>
					<span className="sr-only">{this.props.text}</span>
				</div>
			</div>
		);
	}
}

export default Loader;

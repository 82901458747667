const $ = require('jquery');
$(document).ready(function () {
	const $agreementsContainer = $('#agreement-form');
	if ($agreementsContainer.length > 0) {
		const filesList = $agreementsContainer.find('#agreement-files-list');

		$(document).on('click', '.agreement-file-add-button', function (e) {
			e.preventDefault();

			const button = $(this);
			const loader = button.find('.fa');
			// Disable button and add loader
			loader.removeClass('d-none').prop('disabled', true);

			// Create a new FormData object.
			const formData = new FormData();
			const fileTitle = $('#agreement-file-add-title').val();
			const fileText = $('#agreement-file-add-text').val();
			const fileSelect = document.getElementById('agreement-file-add-file');
			const fileFile = fileSelect.files[0];

			formData.append('fileTitle', fileTitle);
			formData.append('fileText', fileText);
			formData.append('fileFile', fileFile);

			const createFile = fileModule.createFile(formData);
			createFile.done(function (data) {
				// console.log(data);
				if (data.success === true) {
					// Create hidden input for file
					const inputs = filesList.find('input');
					let inputCount = inputs.length;
					let newWidget = filesList.attr('data-prototype');
					newWidget = newWidget.replace(/__name__/g, inputCount);
					inputCount++;
					// create a new list element and add it to the list
					$(newWidget).val(data.id).appendTo(filesList);

					// Create  new element in table for file
					const table = $('.agreement-current-files');
					const tableRow = fileModule.createTableRow(data);
					table.find('tbody').append(tableRow);
					tableRow.fadeTo(100, 0.3, function () {
						$(this).fadeTo(500, 1.0);
					});

					// Reset formfields
					$('#agreement-file-add-title').val('');
					$('#agreement-file-add-text').val('');
					$('#agreement-file-add-file').val('');

					$.notify({
						// options
						message: 'Tillegg lastet opp. Husk å lagre avtalen før du går videre!',
						icon: 'fa fa-check'
					}, {
						// settings
						type: 'success'
					});
				} else {
					console.log('ERROR', data);
					$.notify({
						// options
						message: 'Kunne ikke laste opp tillegget',
						icon: 'fa fa-times'
					}, {
						// settings
						type: 'error'
					});
				}

				loader.addClass('d-none').prop('disabled', false);
			});
		});

		// Handle remove file from agreement
		$(document).on('click', '.delFile', function (e) {
			e.preventDefault();
			const button = $(this);
			button.find('.fa-times').addClass('d-none');
			button.find('.fa-spinner').removeClass('d-none');
			const fileId = button.data('fileid');
			let agreementId = null;
			if ($agreementsContainer.data('agreementid')) {
				agreementId = $agreementsContainer.data('agreementid');
			}

			// Create a new FormData object.
			const formData = new FormData();
			formData.append('agreementid', agreementId);
			formData.append('fileid', fileId);

			const deleteFile = fileModule.deleteFile(formData);
			deleteFile.done((data) => {
				if (data.status === true) {
					// file removed, remove table row and hidden field
					const fileRow = $agreementsContainer.find(`#agreement-file-row-${fileId}`);
					fileRow.remove();

					const hiddenFile = $agreementsContainer.find('.agreement-file-hidden');
					$.each(hiddenFile, (key, field) => {
						const value = $(field).val();
						if (parseInt(value, 10) === parseInt(fileId, 10)) {
							$(field).remove();
						}
					});
				} else {
					button.find('.fa-times').removeClass('d-none');
					button.find('.fa-spinner').addClass('d-none');
					alert('Kunne ikke fjerne fil');
				}
			});
		});
	}
});

const fileModule = (function () {
	return {
		createFile: function (formData) {
			return $.ajax({
				url: '/api/createFile',
				cache: false,
				contentType: false,
				processData: false,
				type: 'POST',
				data: formData
			});
		},

		deleteFile: function (formData) {
			return $.ajax({
				url: '/api/deleteFile',
				cache: false,
				contentType: false,
				processData: false,
				type: 'POST',
				data: formData
			});
		},

		createTableRow: function (data) {
			const row = $(`<tr id="agreement-file-row-${data.id}">`);
			row.append(`<td>${data.title}</td>`);

			let fileText = data.text;
			if (fileText === undefined || fileText === null) {
				fileText = '';
			}
			row.append(`<td>${fileText}</td>`);

			const icon = $(`<i class="fa fa-file-${data.fileExtension}-o" aria-hidden="true"></i> `);
			const link = $('<a>');
			link.attr('href', data.filePath).attr('title', `Last ned / se filen ${data.fileName}`).attr('target', '_new');
			link.html('&nbsp;Last ned / se fil');
			const td = $('<td>');
			td.append(icon);
			td.append(link);

			row.append(td);
			row.append(`<td class="text-right"><a href="#delFile" class="delFile" data-fileid="${data.id}"><i class="fa fa-times"></i><i class="fa fa-spinner fa-spin fa-fw d-none"></i></a></td>`);

			return row;
		}
	};
})();

import APIAgreementVersion from '../types/APIAgreementVersion';
import AgreementVersion from '../types/AgreementVersion';
import APIProtocol from '../types/APIProtocol';
import APIAttachment from '../types/APIAttachment';
import APIAddendum from '../types/APIAddendum';
import File from '../types/File';
import Agreement from '../types/Agreement';
import APIAgreementResponse from '../types/APIAgreementResponse';
import APIAgreementsResponse from '../types/APIAgreementsResponse';
import APIAgreement from '../types/APIAgreement';

interface Included {
	versions: Map<string, APIAgreementVersion>,
	addendums: Map<string, APIAddendum>,
	attachments: Map<string, APIAttachment>,
	protocols: Map<string, APIProtocol>,
	agreements: Map<string, APIAgreement>
}

class Transformers {
	static agreementVersionsFromAgreementsResponse(response: APIAgreementsResponse): AgreementVersion[] {
		const included = Transformers.mapIncluded(response)

		// Map the returned agreements and their current versions into an array
		// of simplified AgreementVersion objects:
		const results: AgreementVersion[] = response.data.map((agreement) => {
			const currentVersion = included.versions.get(agreement.relationships.current.data.id);
			if (currentVersion === undefined) {
				throw new Error('Linked AgreementVersion not included in response.');
			}
			return Transformers.agreementVersionFromAPIAgreementVersion(currentVersion, agreement, included)
		});

		return results;
	};

	static agreementFromAPIAgreementResponse(response: APIAgreementResponse): Agreement {
		const included = Transformers.mapIncluded(response);

		return Transformers.agreementFromAPIAgreement(response.data, included);
	}

	static fileFromAPIFile(file: APIProtocol | APIAttachment | APIAddendum): File {
		const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
		const exponent = Math.floor(Math.log(parseInt(file.attributes.fileSize)) / Math.log(1024));
		let size = (parseInt(file.attributes.fileSize) / Math.pow(1024, exponent))
			.toFixed(exponent === 0 || exponent === 1 ? 0 : 1);
		return {
			fileURL: file.attributes.fileURL,
			id: file.id,
			size: `${size} ${sizes[exponent]}`,
			title: file.attributes.title
		}
	}

	static agreementTypeToTypeName(type?: string): string {
		return type === 'HA' ? 'Hovedavtale' :
				type === 'TA' ? 'Tariffavtale' :
					type === 'AA' ? 'Annen avtale' : '';
	}

	private static mapIncluded(response: APIAgreementResponse | APIAgreementsResponse): Included {
		const versions = new Map<string, APIAgreementVersion>();
		const addendums = new Map<string, APIAddendum>();
		const attachments = new Map<string, APIAttachment>();
		const protocols = new Map<string, APIProtocol>();
		const agreements = new Map<string, APIAgreement>();

		// Sort included items by type:
		response.included.forEach((item) => {
			switch (item.type) {
				case 'addendums':
					addendums.set(item.id, item as APIAddendum);
					break;
				case 'agreement-versions':
					versions.set(item.id, item as APIAgreementVersion);
					break;
				case 'attachments':
					attachments.set(item.id, item as APIAttachment);
					break;
				case 'protocols':
					protocols.set(item.id, item as APIProtocol);
					break;
				case 'agreements':
					agreements.set(item.id, item as APIAgreement);
					break;
			}
		});

		return {versions, addendums, attachments, protocols, agreements}
	}

	private static addendumsFromIncluded(version: APIAgreementVersion, included: Included): (File | null)[] {
		return version.relationships.addendums.data.map((link) => {
			const includedAddendums = included.addendums.get(link.id)
			if (includedAddendums === undefined) {
				return null
			}

			return Transformers.fileFromAPIFile(includedAddendums)
		})
	}

	private static attachmentsFromIncluded(version: APIAgreementVersion, included: Included): (File | null)[] {
		return version.relationships.attachments.data.map((link) => {
			const includedAttachments = included.attachments.get(link.id)
			if (includedAttachments === undefined) {
				return null
			}

			return Transformers.fileFromAPIFile(includedAttachments)
		})
	}

	private static protocolsFromIncluded(version: APIAgreementVersion, included: Included): (File | null)[] {
		return version.relationships.protocols.data.map((link) => {
			const includedProtocols = included.protocols.get(link.id)
			if (includedProtocols === undefined) {
				return null
			}

			return Transformers.fileFromAPIFile(includedProtocols)
		})
	}

	private static childrenFromIncluded(agreement: APIAgreement, included: Included): Agreement[] {
		return agreement.relationships.children.data.map((child) => {
			const includedChild = included.agreements.get(child.id)
			if (includedChild === undefined) {
				throw new Error('Linked child Agreement not included in response.')
			}

			return this.agreementFromAPIAgreement(includedChild, included);
		})
	}

	private static parentsFromIncluded(agreement: APIAgreement, included: Included): Agreement[] {
		return agreement.relationships.parents.data.map((parent) => {
			const includedParent = included.agreements.get(parent.id)
			if (includedParent === undefined) {
				throw new Error('Linked parent Agreement not included in response.')
			}

			return this.agreementFromAPIAgreement(includedParent, included);
		})
	}

	private static versionsFromIncluded(agreement: APIAgreement, included: Included): AgreementVersion[] {
		return agreement.relationships.versions.data.map((version) => {
			const includedVersion = included.versions.get(version.id)
			if (includedVersion === undefined) {
				throw new Error('Linked version Agreement not included in response.')
			}

			return Transformers.agreementVersionFromAPIAgreementVersion(includedVersion, agreement, included);
		})
	}

	private static agreementFromAPIAgreement(agreement: APIAgreement, included: Included): Agreement {
		const current = included.versions.get(agreement.relationships.current.data?.id);

		if (!current) {
			throw new Error('Current version of Agreement not included in response.');
		}

		const currentVersion = Transformers.agreementVersionFromAPIAgreementVersion(current, agreement, included);
		const children = Transformers.childrenFromIncluded(agreement, included);
		const parents = Transformers.parentsFromIncluded(agreement, included);
		const versions = Transformers.versionsFromIncluded(agreement, included);

		return {
			id: agreement.id,
			number: agreement.attributes.number,
			type: agreement.attributes.type,
			currentVersion,
			children,
			parents,
			versions
		}
	}

	private static agreementVersionFromAPIAgreementVersion(version: APIAgreementVersion, agreement: APIAgreement, included: Included): AgreementVersion {
		const addendums = Transformers.addendumsFromIncluded(version, included);
		const attachments = Transformers.attachmentsFromIncluded(version, included);
		const protocols = Transformers.protocolsFromIncluded(version, included);

		return {
			agreementID: agreement.id,
			description: version.attributes.description || '',
			fileURL: version.attributes.fileURL,
			id: version.id,
			number: agreement.attributes.number,
			title: version.attributes.titleExternal || version.attributes.title,
			type: agreement.attributes.type,
			validFrom: new Date(version.attributes.validFrom),
			validTo: new Date(version.attributes.validTo),

			addendums: addendums,
			attachments: attachments,
			protocols: protocols
		}
	}
}

export default Transformers;

const $ = require('jquery');
$(document).ready(function () {
	const $agreementsContainer = $('#agreement-form');
	if ($agreementsContainer.length > 0) {
		const mediaList = $agreementsContainer.find('#agreement-media-list');

		$(document).on('click', '.agreement-add-media', function (e) {
			e.preventDefault();

			const button = $(this);
			const loader = button.find('.fa');
			// Disable button and add loader
			loader.removeClass('d-none').prop('disabled', true);

			// Create a new FormData object.
			const formData = new FormData();
			const mediaTitle = $('#mediaTitle').val();
			const fileSelect = document.getElementById('mediaFile');
			const mediaFile = fileSelect.files[0];

			formData.append('mediaTitle', mediaTitle);
			formData.append('mediaFile', mediaFile);

			const createMedia = mediaModule.createMedia(formData);
			createMedia.done(function (data) {
				// console.log(data);
				if (data.success === true) {
					// Create hidden input for media
					const inputs = mediaList.find('input');
					let inputCount = inputs.length;
					let newWidget = mediaList.attr('data-prototype');
					newWidget = newWidget.replace(/__name__/g, inputCount);
					inputCount++;
					// create a new list element and add it to the list
					$(newWidget).val(data.id).appendTo(mediaList);

					// Create  new element in table for media
					const table = $('.agreement-media-table');
					const tableRow = mediaModule.createTableRow(data);
					table.find('tbody').append(tableRow);

					// Reset formfields
					$('#mediaTitle').val('');
					$('#mediaFile').val('');

					$.notify({
						// options
						message: 'Vedlegg lastet opp. Husk å lagre avtalen før du går videre!',
						icon: 'fa fa-check'
					}, {
						// settings
						type: 'success'
					});
				} else {
					console.log('ERROR', data);
					$.notify({
						// options
						message: 'Kunne ikke laste opp vedlegget.',
						icon: 'fa fa-times'
					}, {
						// settings
						type: 'error'
					});
				}

				loader.addClass('d-none').prop('disabled', false);
			});
		});

		// Handle remove media from agreement
		$(document).on('click', '.delMedia', function (e) {
			e.preventDefault();
			const button = $(this);
			button.find('.fa-times').addClass('d-none');
			button.find('.fa-spinner').removeClass('d-none');

			const mediaId = $(this).data('mediaid');
			let agreementId = null;
			if ($agreementsContainer.data('agreementid')) {
				agreementId = $agreementsContainer.data('agreementid');
			}

			// Create a new FormData object.
			const formData = new FormData();
			formData.append('agreementid', agreementId);
			formData.append('mediaid', mediaId);

			const deleteMedia = mediaModule.deleteMedia(formData);
			deleteMedia.done((data) => {
				if (data.status === true) {
					// Media removed, remove table row and hidden field
					const mediaRow = $agreementsContainer.find(`#agreement-media-row-${mediaId}`);
					mediaRow.remove();

					const hiddenMedia = $agreementsContainer.find('.agreement-media-hidden');
					$.each(hiddenMedia, (key, field) => {
						const value = $(field).val();
						if (parseInt(value, 10) === parseInt(mediaId, 10)) {
							$(field).remove();
						}
					});
				} else {
					button.find('.fa-times').removeClass('d-none');
					button.find('.fa-spinner').addClass('d-none');
					alert('Kunne ikke fjerne media');
				}
			});
		});
	}
});

const mediaModule = (function () {
	return {
		createMedia: function (formData) {
			return $.ajax({
				url: '/api/createMedia',
				cache: false,
				contentType: false,
				processData: false,
				type: 'POST',
				data: formData
			});
		},

		deleteMedia: function (formData) {
			return $.ajax({
				url: '/api/deleteMedia',
				cache: false,
				contentType: false,
				processData: false,
				type: 'POST',
				data: formData
			});
		},

		createTableRow: function (data) {
			const row = $(`<tr id="agreement-media-row-${data.id}">`);
			row.append(`<td>${data.title}</td>`);
			// row.append(`<td>${data.number}</td>`);

			const icon = $(`<i class="fa fa-file-${data.fileExtension}-o" aria-hidden="true"></i> `);
			const link = $('<a>');
			link.attr('href', data.filePath).attr('title', `Last ned / se filen ${data.fileName}`).attr('target', '_new');
			link.html('&nbsp;Last ned / se fil');
			const td = $('<td>');
			td.append(icon);
			td.append(link);

			row.append(td);
			row.append(`<td class="text-right"><a href="#delMedia" class="delMedia" data-mediaid="${data.id}"><i class="fa fa-times"></i><i class="fa fa-spinner fa-spin fa-fw d-none"></i></a></td>`);

			return row;
		}
	};
})();

import * as React from 'react';

interface SearchProps {
	query: string,
	onChange: (query: string) => void
}
interface SearchState {}

class Search extends React.Component<SearchProps, SearchState> {
	render() {
		return (
			<section className="my-5">
				<div className="ffnext-searchform">
					<form>
						<div className="form-group">
							<label htmlFor="search-input" className="sr-only">Søk etter avtaler</label>
							<input
								className="form-control form-control-lg"
								name="query"
								type="search"
								value={this.props.query}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.onChange(event.target.value)}
								placeholder="Søk etter avtaletittel, avtalenummer eller tekst"
								autoFocus
							/>
							<button type="submit">
								<span className="sr-only">Utfør søk</span>
								<i className="fficon fficon-search"></i>
							</button>
						</div>
					</form>
				</div>
			</section>
		);
	}
}

export default Search;

const $ = require('jquery');
global.jQuery = $;
global.$ = $;
global.Popper = require('popper.js');

require('bootstrap');
const tablesort = require('tablesort');
window.Tablesort = tablesort;
require('tablesort/src/sorts/tablesort.number.js');
// const Sticky = require('sticky-js');
require('bootstrap-notify');
require('./includes/branches.js');
require('./includes/protocols.js');
require('./includes/media.js');
require('./includes/files.js');
require('./includes/versions.js');
require('./react/src/index');

$(document).ready(() => {
	const branchTables = document.getElementsByClassName('branch-table');

	if (branchTables.length) {
		// Init tablesort on table
		tablesort(branchTables[0], {
			descending: false
		});
	}

	// Agreements tables/lists
	if ($('#agreement-table').length > 0) {
		 const table = document.getElementById('agreement-table');

		// Init tablesort on table
		tablesort(table, {
			descending: false
		});
	}

	// Expired agreements table
	if ($('#agreement-table-expired').length > 0) {
		const tableExpired = document.getElementById('agreement-table-expired');

	   // Init tablesort on table
	   tablesort(tableExpired, {
		   descending: false
	   });
	}

	// Sticky menu
	/* const stickyParams = {
		stickyClass: 'stuck',
		center: true
	};
	const sticky = new Sticky('.sticky', stickyParams); // eslint-disable-line*/

	// Cancel add/edit agreement and go back to previous view
	const cancelButton = document.getElementById('form_cancel');

	$(cancelButton).click(function () {
		window.history.back(1);
	});

	// Handle notifications
	$.notifyDefaults({
		placement: {
			from: 'top',
			align: 'center'
		},
		animate: {
			enter: 'animated bounceInDown',
			exit: 'animated bounceOutUp'
		}
	});
	const notifications = $('.notify');
	if (notifications.length > 0) {
		$.each(notifications, function (index, notification) {
			const notifyText = $(notification).text();
			const notifyStatus = $(notification).data('status');
			let notifyIcon = 'fa fa-check';
			if (notifyStatus === 'danger') {
				notifyIcon = 'fa fa-times';
			}

			$.notify({
				// options
				message: notifyText,
				icon: notifyIcon
			}, {
				// settings
				type: notifyStatus
			});
		});
	}

	// AGREEMENT ACTIONS
	// Replace space with dashes on agreementnumber
	const slugContainer = $('.agreement-slug > span');
	$(document).on('keyup blur', '.new-agreement-form #form_number', function () {
		const input = $(this);
		const currentVal = input.val();
		const slug = generateSlug(currentVal);
		slugContainer.text(slug);
	});

	// Check agreementnumber before submitting new agreement form
	$(document).on('click', '#form_submit', () => {
		const form = $('#form_new_agreement');
		const agreementNumber = form.find('#form_number');
		if (agreementNumber.val() !== '') {
			const agreementSlug = generateSlug(agreementNumber.val());
			const branch = form.find('#form_branch');
			// Check if there is an agreement with this number
			$.ajax({
				url: `/api/agreement/${branch.val()}/${agreementSlug}`,
				type: 'GET'
			}).done(function (data) {
				if (data.length > 0) {
					$.notify({
						// options
						message: 'En avtale har allerede dette avtalnummeret.',
						icon: 'fa fa-times'
					}, {
						// settings
						type: 'danger'
					});
				} else {
					form.submit();
				}
			});
		} else {
			form.submit();
		}
	});
});

function generateSlug(number) {
	if (number) {
		const slug = number.replace(/\s+|[,/]/g, '-');
		return slug.toUpperCase();
	}
	return false;
}


import * as React from 'react';
import {RouteComponentProps, Link} from 'react-router-dom';
import API from '../services/API';
import Transformers from '../services/Transformers';
import Agreement from '../types/Agreement';
import Loader from '../components/Loader';
import AgreementVersion from '../types/AgreementVersion';
import DownloadFileLink, { DownloadFileLinkType } from '../components/DownloadFileLink';

interface AgreementPageProps extends RouteComponentProps<{id?: string, versionID?: string, slug?: string}> {}
interface AgreementPageState {
	agreement?: Agreement,
	loading: boolean,
	error?: Error
}

class AgreementPage extends React.Component<AgreementPageProps, AgreementPageState> {
	constructor(props: AgreementPageProps) {
		super(props);
		this.state = {
			loading: true
		};
	}

	componentDidMount() {
		this.fetchAgreement();
	}

	componentDidUpdate(prevProps: AgreementPageProps) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.fetchAgreement();
		}
	}

	private fetchAgreement = async () => {
		try {
			var agreement: Agreement | undefined = undefined;
			if (this.props.match.params.slug) {
				const response = await API.getAgreementBySlug(this.props.match.params.slug);
				agreement = response ? 
					Transformers.agreementFromAPIAgreementResponse(response) :
					undefined;
			}
			else if (this.props.match.params.id) {
				const response = await API.getAgreementByUUID(this.props.match.params.id);
				agreement = response ?
					Transformers.agreementFromAPIAgreementResponse(response) :
					undefined;
			}

			this.setState({
				agreement,
				loading: false
			});
		} catch (error) {
			console.error(error);
			this.setState({
				loading: false,
				error
			});
		}
	}

	private renderLoading = () => {
		return (
			<div className="container-narrow container-pad-both-sides">
				<Loader text="Laster avtale..." />
			</div>
		)
	}

	private renderError = () => {
		return (
			<div className="container-narrow container-pad-both-sides">
				<div className="alert alert-danger">
					<strong>Oida!</strong> Noe gikk galt mens du prøvde å hente detaljer om avtalen.
				</div>
			</div>
		)
	}

	private render404 = () => {
		return (
			<div className="container-narrow container-pad-both-sides">
				<ol className="breadcrumb p-0 mb-6">
					<li className="breadcrumb-item">
						<Link to={`/`}>HJEM</Link>
					</li>
					<li className="breadcrumb-item active">
						404 - IKKE FUNNET
					</li>
				</ol>

				<div className="alert alert-danger">
					<strong>Oida! Ikke funnet!</strong> Vi kan ikke finne siden du ser etter. Kanskje finner du det du leter etter på en av våre andre sider.
				</div>
			</div>
		)
	}

	render() {
		if (this.state.loading) {
			return this.renderLoading();
		}

		if (this.state.error) {
			return this.renderError();
		}

		if (!this.state.agreement) {
			return this.render404();
		}

		// Try to resolve which version of the agreement to display:
		let hasMultipleVersions = this.state.agreement.versions.length > 1
		let isCurrent = false;
		let displayedVersion: AgreementVersion | undefined = undefined;
		if (this.props.match.params.versionID) {
			const selectedVersion = this.state.agreement.versions.filter((agreementVersion) => agreementVersion.id === this.props.match.params.versionID);
			if (selectedVersion?.length === 1) {
				displayedVersion = selectedVersion[0];
				isCurrent = displayedVersion.id === this.state.agreement.currentVersion.id;
			}
		} else {
			displayedVersion = this.state.agreement.currentVersion;
			isCurrent = true;
		}

		if (!displayedVersion) {
			return this.render404();
		}

		// Try to figure out which version is the one before the displayed
		// version so we can fall back to it's document file if needed:
		let versionsLatestToOldest = [...this.state.agreement.versions].sort((a, b) => {
			return b.validFrom.valueOf() - a.validFrom.valueOf();
		})
		let previousVersion: AgreementVersion | null = null;

		let nextIsPreviousVersion = false;
		for(const version of versionsLatestToOldest) {
			if(version.id == displayedVersion.id) {
				nextIsPreviousVersion = true;
			} else if (nextIsPreviousVersion) {
				previousVersion = version
				break;
			}
		}

		const typeName = Transformers.agreementTypeToTypeName(this.state.agreement.type);

		const dateFormatter = new Intl.DateTimeFormat('nb', {
			month: '2-digit',
			day: '2-digit',
			year: 'numeric'
		});

		const yearFormatter = new Intl.DateTimeFormat('nb', {
			year: 'numeric'
		});

		return (
			<div className="container-narrow container-pad-both-sides">
				<ol className="breadcrumb p-0 mb-6">
					<li className="breadcrumb-item">
						<Link to={`/`}>HJEM</Link>
					</li>
					<li className="breadcrumb-item active">
						{this.state.agreement.number.toLocaleUpperCase()}
					</li>
				</ol>
				<header className="mb-4">
					<h1 className="h2">{displayedVersion.title}</h1>
					<h2 className="h3 d-flex align-items-baseline">
						{typeName}
						<span className="ml-2 font-weight-normal">
							{yearFormatter.format(displayedVersion.validFrom)}-{yearFormatter.format(displayedVersion.validTo)}
							{
								!isCurrent && <i className="ml-2 text-warning fa fa-exclamation-triangle" title="Dette er ikke den gjeldende versjonen av denne avtalen."></i>
							}
						</span>
						{hasMultipleVersions && <>
							<div className="ml-auto dropdown d-inline-block">
								<button
									className="btn btn-link dropdown-toggle align-baseline"
									type="button"
									id="ff-agreement-version-dropdown-menu"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
								>
									Andre versjoner
								</button>
								<div className="dropdown-menu dropdown-menu-right" aria-labelledby="ff-agreement-version-dropdown-menu">
									{this.state.agreement?.versions
										.sort((a, b) => b.validFrom.getTime() - a.validFrom.getTime())
										.map((version) => {
											return (
												<Link key={version.id} className={`dropdown-item${this.props.match.params.versionID === version.id ? ' active' : ''}`} to={`/avtaler/${this.state.agreement?.id}/versjoner/${version.id}`}>
													{yearFormatter.format(version.validFrom)} - {yearFormatter.format(version.validTo)}
												</Link>
											)
										})
									}
								</div>
							</div>
						</>}
					</h2>
				</header>
				<section className="mb-6">
					<div>
						Avtalenummer: <strong>{this.state.agreement?.number}</strong>
					</div>
					<div className="mb-3">
						Gyldig: <strong>{dateFormatter.format(displayedVersion.validFrom)} - {dateFormatter.format(displayedVersion.validTo)}</strong>
					</div>
					<div>
						{displayedVersion.description}
					</div>
				</section>
				{(displayedVersion.attachments.length +
					displayedVersion.addendums.length +
					displayedVersion.protocols.length > 0 ||
					displayedVersion.fileURL ||
					(previousVersion && previousVersion.fileURL)
				) && (
					<section className="mb-6">
						<h3>Last ned dokumenter</h3>
						{!displayedVersion.fileURL && previousVersion && previousVersion.fileURL && (
							<div className="alert alert-info">
								<strong>Obs!</strong> Avtaleteksten er ikke klar enda. Vi oppdaterer avtalen så fort ny tekst foreligger. Enn
								så lenge kan du laste ned forrige versjon av avtalen under.
							</div>
						)}
						<div className="list-group list-group-no-border-x">
							{/* Display the selected version's document if we have one: */}
							{displayedVersion.fileURL && (
								<div className="d-flex align-items-center list-group-item list-group-item-action justify-content-between ffnext-animate-arrow">
									<span className="d-inline-flex flex-shrink-0" style={{width: '150px'}}>
										Avtaletekst
									</span>
									<span className="px-3 flex-grow-1">
										{displayedVersion.title}
									</span>
									<DownloadFileLink href={displayedVersion.fileURL} uuid={displayedVersion.id} 
										type={DownloadFileLinkType.Agreement} />
								</div>
							)}

							{/* Otherwise, display the document from a previous version: */}
							{!displayedVersion.fileURL && previousVersion && previousVersion.fileURL && (
								<div className="d-flex align-items-center list-group-item list-group-item-action justify-content-between ffnext-animate-arrow">
									<span className="d-inline-flex flex-shrink-0" style={{width: '150px'}}>
										Avtaletekst for {yearFormatter.format(previousVersion.validFrom)}-{yearFormatter.format(previousVersion.validTo)}
									</span>
									<span className="px-3 flex-grow-1">
										{previousVersion.title}
									</span>
									<DownloadFileLink href={previousVersion.fileURL} uuid={previousVersion.id}
										type={DownloadFileLinkType.Agreement} />
								</div>
							)}

							{displayedVersion.addendums.map((addendum) => {
								if(addendum === null) {
									console.warn('Addendum not included in server response');
									return false;
								}

								return (
									<div key={addendum.id} className="d-flex align-items-center list-group-item list-group-item-action justify-content-between ffnext-animate-arrow">
										<span className="d-inline-flex flex-shrink-0" style={{width: '150px'}}>
											Tilleggsdokument
										</span>
										<span className="px-3 flex-grow-1">
											{addendum.title}
										</span>
										<DownloadFileLink href={addendum.fileURL} uuid={addendum.id}
											type={DownloadFileLinkType.Addendum} />
									</div>
								);
							})}

							{displayedVersion.protocols.map((protocol) => {
								if(protocol === null) {
									console.warn('Protocol not included in server response');
									return false;
								}

								return (
									<div key={protocol.id} className="d-flex align-items-center list-group-item list-group-item-action justify-content-between ffnext-animate-arrow">
										<span className="d-inline-flex flex-shrink-0" style={{width: '150px'}}>
											Protokoll
										</span>
										<span className="px-3 flex-grow-1">
											{protocol.title}
										</span>
										<DownloadFileLink href={protocol.fileURL} uuid={protocol.id}
											type={DownloadFileLinkType.Protocol} />
									</div>
								);
							})}

							{displayedVersion.attachments.map((attachment) => {
								if(attachment === null) {
									console.warn('Attachment not included in server response');
									return false;
								}

								return (
									<div key={attachment.id} className="d-flex align-items-center list-group-item list-group-item-action justify-content-between ffnext-animate-arrow">
										<span className="d-inline-flex flex-shrink-0" style={{width: '150px'}}>
											Vedlegg
										</span>
										<span className="px-3 flex-grow-1">
											{attachment.title}
										</span>
										<DownloadFileLink href={attachment.fileURL} uuid={attachment.id}
											type={DownloadFileLinkType.Attachment} />
									</div>
								);
							})}
						</div>
					</section>
				)}
				{(this.state.agreement.children.length > 0 || this.state.agreement.parents.length > 0) && (
					<section className="mb-6">
						<h3>Relaterte avtaler</h3>
						<nav className="ffnext-list-link">
							{this.state.agreement.parents.map((parent) => {
								return (
									<div key={parent.id} className="w-100 ml-0">
										<Link className="ffnext-animate-arrow d-flex" to={`/avtaler/${parent.id}`}>
											<span className="d-inline-flex flex-shrink-0" style={{width: '150px'}}>{Transformers.agreementTypeToTypeName(parent.type)}</span>
											<span className="d-inline-flex flex-grow-1">{parent.currentVersion?.title}</span>
											<i className="ffnext-link-arrow-right mx-3"></i>
										</Link>
									</div>
								);
							})}

							{this.state.agreement.children.map((child) => {
								return (
									<div key={child.id} className="w-100 ml-0">
										<Link className="ffnext-animate-arrow d-flex" to={`/avtaler/${child.id}`}>
											<span className="d-inline-flex flex-shrink-0" style={{width: '150px'}}>{Transformers.agreementTypeToTypeName(child.type)}</span>
											<span className="d-inline-flex flex-grow-1">{child.currentVersion?.title}</span>
											<i className="ffnext-link-arrow-right mx-3"></i>
										</Link>
									</div>
								);
							})}
						</nav>
					</section>
				)}
			</div>
		);
	}
}

export default AgreementPage;

import * as React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import Header from './components/Header';
import AgreementPage from './pages/AgreementPage';
import HomePage from './pages/HomePage';

class App extends React.Component {
	render() {
		return (
			<div className="fagforbundet-bootstrap">
				<Header/>
				<BrowserRouter>
					<Route exact path="/avtaler/:id/versjoner/:versionID" component={AgreementPage} />
					<Route exact path="/avtaler/:id" component={AgreementPage} />
					<Route exact path="/avtaler/slug/:slug" component={AgreementPage} />
					<Route exact path="/" component={HomePage} />
				</BrowserRouter>
			</div>
		);
	}
}

export default App;

import * as React from 'react';

class Header extends React.Component {
	render() {
		const adLoginURL = `/ad/login`;
		
		return (
			<header>
				<nav>
					<div className="container">
						<div className="row align-items-center justify-content-between flex-nowrap">
							{/* Logo */}
							<div
								className="col-auto mr-auto"
								style={{ flexShrink: 1 }}
							>
								<a href="/">
									<img
										src="/images/ff-avtaledatabase-logo.png"
										className="img-fluid"
										alt="Fagforbundets avtaledatabase"
										style={{
											maxHeight: "50px"
										}}
									/>
								</a>
							</div>

							{/* Ad Login Button */}
							<div className="col-auto">
								<a
									className='btn btn-link'
									href={adLoginURL}
									title="Logg inn og administrer Fagforbundets Avtaledatabase"
									style={{
										textTransform: "none", // Override some stupidity in the main stylesheet.
									}}
								>
									Logg&nbsp;inn&nbsp;<i className="fficon fficon-user" aria-hidden="true" />
								</a>
							</div>
						</div>
					</div>
					<style type="text/css">{`
						.fagforbundet-bootstrap header a.btn-link {
							color: #3c3c3b;
							padding: 0.25rem 0.5rem;
							border-radius: 0;
						}

						.fagforbundet-bootstrap header .btn-link:hover {
							background-color: #fc3729;
							color: #fff;
						}
					`}
					</style>
				</nav>
			</header>
		);
	}
}

export default Header;

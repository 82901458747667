import * as React from 'react';
import AgreementVersion from '../types/AgreementVersion';
import {Link} from 'react-router-dom';

interface AgreementListBlockProps {
	agreement: AgreementVersion
}
interface AgreementListBlockState {}

class AgreementListBlock extends React.Component<
	AgreementListBlockProps, AgreementListBlockState
> {
	render() {
		const typeImage =
			this.props.agreement.type === 'HA' ? 'ha' :
				this.props.agreement.type === 'TA' ? 'hta' :
					'sa';

		return (
			<div className="row">
				<div className="col-auto d-none d-sm-block">
					<span className={`ffnext-agreement-type ${typeImage}`}>
						<span>
							{this.props.agreement.number}
						</span>
					</span>
				</div>
				<div className="col mb-2">
					<h3 className="h3">
						<Link
							to={`/avtaler/${this.props.agreement.agreementID}`}
							aria-controls={this.props.agreement.agreementID}
							title={`Se detaljer om avtale ${this.props.agreement.title}`}
						>
							{this.props.agreement.title}
						</Link>
					</h3>
					<p>{this.props.agreement.description}</p>
				</div>
			</div>
		);
	}
}

export default AgreementListBlock;

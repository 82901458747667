import * as React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import '@fagforbundet/bootstrap-theme/assets/scss/main-webpack.scss';

const root = document.getElementById('root');

if(root) {
	ReactDOM.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>,
		root
	);
}

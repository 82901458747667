const $ = require('jquery');
$(document).ready(function () {
	const $agreementsContainer = $('#agreement-form');
	if ($agreementsContainer.length > 0) {
		const protocolList = $agreementsContainer.find('#agreement-protocols-list');

		$(document).on('click', '.agreement-add-protocol', function (e) {
			e.preventDefault();

			const button = $(this);
			const loader = button.find('.fa');
			// Disable button and add loader
			loader.removeClass('d-none').prop('disabled', true);

			// Create a new FormData object.
			const formData = new FormData();
			const protocolTitle = $('#protocolTitle').val();
			const protocolId = $('#protocolId').val();
			const fileSelect = document.getElementById('protocolFile');
			const protocolFile = fileSelect.files[0];

			if (protocolTitle === '' || protocolFile === undefined) {
				$.notify({
					// options
					message: 'Vennligst velg fil, og fyll inn tittel',
					icon: 'fa fa-times'
				}, {
					// settings
					type: 'danger'
				});
				loader.addClass('d-none').prop('disabled', false);
				return false;
			}

			formData.append('protocolTitle', protocolTitle);
			formData.append('protocolId', protocolId);
			formData.append('protocolFile', protocolFile);

			const createProtocol = protocolModule.createProtocol(formData);
			createProtocol.done(function (data) {
				if (data.success === true) {
					// Create hidden input for protocol
					const inputs = protocolList.find('input');
					let inputCount = inputs.length;
					let newWidget = protocolList.attr('data-prototype');
					newWidget = newWidget.replace(/__name__/g, inputCount);
					inputCount++;
					// create a new list element and add it to the list
					$(newWidget).val(data.id).appendTo(protocolList);

					// Create  new element in table for protocols
					const table = $('.agreement-protocols-table');
					const tableRow = protocolModule.createTableRow(data);
					table.find('tbody').append(tableRow);

					// Reset formfields
					$('#protocolTitle').val('');
					$('#protocolId').val('');
					$('#protocolFile').val('');

					// Notify user
					$.notify({
						// options
						message: 'Protokoll lagret. Husk å lagre avtalen før du går videre!',
						icon: 'fa fa-check'
					}, {
						// settings
						type: 'success'
					});
				} else {
					$.notify({
						// options
						message: 'Kunne ikke lagre protokoll',
						icon: 'fa fa-times'
					}, {
						// settings
						type: 'danger'
					});
				}

				loader.addClass('d-none').prop('disabled', false);
			});
		});

		// Handle remove protocol from agreement
		$(document).on('click', '.delProtocol', function (e) {
			e.preventDefault();
			const protocolId = $(this).data('protocolid');
			let agreementId = null;
			if ($agreementsContainer.data('agreementid')) {
				agreementId = $agreementsContainer.data('agreementid');
			}

			// Create a new FormData object.
			const formData = new FormData();
			formData.append('agreementid', agreementId);
			formData.append('protocolid', protocolId);

			const deleteProtocol = protocolModule.deleteProtocol(formData);
			deleteProtocol.done((data) => {
				if (data.status === true) {
					// Protocol removed, remove table row and hidden field
					const protocolRow = $agreementsContainer.find(`#agreement-protocol-row-${protocolId}`);
					protocolRow.remove();

					const hiddenProtocols = $agreementsContainer.find('.agreement-protocol-hidden');
					$.each(hiddenProtocols, (key, field) => {
						const value = $(field).val();
						if (parseInt(value, 10) === parseInt(protocolId, 10)) {
							$(field).remove();
						}
					});
					// Notify user
					$.notify({
						// options
						message: 'Protokoll fjernet. Husk å lagre avtalen før du går videre!',
						icon: 'fa fa-check'
					}, {
						// settings
						type: 'success'
					});
				} else {
					// Notify user
					$.notify({
						// options
						message: 'Kunne ikke slette protokoll',
						icon: 'fa fa-times'
					}, {
						// settings
						type: 'danger'
					});
				}
			});
		});
	}
});

const protocolModule = (function () {
	return {
		createProtocol: function (formData) {
			return $.ajax({
				url: '/api/createProtocol',
				cache: false,
				contentType: false,
				processData: false,
				type: 'POST',
				data: formData
			});
		},

		deleteProtocol: function (formData) {
			return $.ajax({
				url: '/api/deleteProtocol',
				cache: false,
				contentType: false,
				processData: false,
				type: 'POST',
				data: formData
			});
		},

		createTableRow: function (data) {
			const row = $(`<tr id="agreement-protocol-row-${data.id}">`);
			row.append(`<td>${data.title}</td>`);

			const icon = $(`<i class="fa fa-file-${data.fileExtension}-o" aria-hidden="true"></i> `);
			const link = $('<a>');
			link.attr('href', data.filePath).attr('title', `Last ned / se filen ${data.fileName}`).attr('target', '_new');
			link.text(' Last ned / se fil');
			const td = $('<td>');
			td.append(icon);
			td.append(link);

			row.append(td);
			row.append(`<td><a href="#delProtocol" class="delProtocol" data-protocolid="${data.id}"><i class="fa fa-times"></i></a></td>`);

			return row;
		}
	};
})();

const $ = require('jquery');
$(document).ready(function () {
	const agreementVersionTable = $('.agreement-versions-table');
	if (agreementVersionTable.length > 0) {
		$(document).on('click', '.agreement-version-set-active-button', function (e) {
			e.preventDefault();

			const button = $(this);
			const agreementNumber = button.data('agreementnumber');
			const agreementId = button.data('agreementid');
			const branchId = button.data('branchid');
			// console.log(agreementId, agreementNumber);

			const buttons = $('.agreement-version-set-active-button');
			buttons.prop('disabled', true);
			buttons.find('.fa').removeClass('d-none');

			const setActive = versionsModule.setActiveVersion(agreementNumber, agreementId, branchId);
			setActive.done((data) => {
				if (data.success) {
					// Reload viewAgreement
					window.location.href = `/branch/${branchId}/agreement/${agreementNumber}`;
				}
			});
		});
	}
});


const versionsModule = (function () {
	return {
		setActiveVersion: function (agreementNumber, agreementId, branchId) {
			const url = `/api/branch/${branchId}/agreement/${agreementNumber}/setActiveVersion`;
			return $.ajax({
				url: url,
				type: 'POST',
				data: `agreementId=${agreementId}`
			});
		}
	};
})();

import { DownloadFileLinkType } from '../components/DownloadFileLink';
import APIAgreementResponse from '../types/APIAgreementResponse';
import APIAgreementsResponse from '../types/APIAgreementsResponse';

class API {
	static async search(query: string): Promise<APIAgreementsResponse> {
		const include = [
			'current'
		];
		const url = `/api/v1/search/agreements?filter[query]=${encodeURIComponent(query)}&include=${include.join(',')}`
		const response = await fetch(url);
		if (response.status != 200) {
			throw new Error('Server responded with unexpected HTTP status.')
		}

		const json = await response.json();
		return json;
	};

	private static getAgreementIncludes(): Array<string> {
		const includes = [
			'current',
			'current.addendums',
			'current.attachments',
			'current.protocols',
			'children',
			'parents',
			'versions',
			'versions.addendums',
			'versions.attachments',
			'versions.protocols'
		]
		return includes;
	};
	static async getAgreementByUUID(id: string): Promise<APIAgreementResponse | undefined> {
		const includes = this.getAgreementIncludes();
		const url = `/api/v1/agreements/${id}?include=${includes.join(',')}`
		const response = await fetch(url);
		if (response.status === 404) {
			return;
		}
		if (response.status != 200) {
			throw new Error('Server responded with unexpected HTTP status.')
		}

		const json = await response.json();
		return json;
	};

	static async getAgreementBySlug(slug: string): Promise<APIAgreementResponse | undefined> {
		const includes = this.getAgreementIncludes();
		const url = `/api/v1/agreements/slug/${slug}?include=${includes.join(',')}`;
		const response = await fetch(url);
		if (response.status === 404) {
			return;
		}
		if (response.status != 200) {
			throw new Error('Server responded with unexpected HTPP status.');
		}

		const json = await response.json();
		return json;
	}

	static async getLastUpdated(): Promise<APIAgreementsResponse> {
		// TODO: This should probably be it's own endpoint, or even better, we
		//       should add sorting and offset/limit to the main search
		//       endpoint so we can just use that.
		const include = [
			'current'
		];
		const url = `/api/v1/search/agreements?include=${include.join(',')}`
		const response = await fetch(url);
		if (response.status != 200) {
			throw new Error("Server responded with unexpected HTTP status.")
		}

		const json = await response.json();
		return json;
	}

	static async countDownload(data: CountDownloadRequest): Promise<any> {
		const url = `/api/v1/file/count`;
		const response = await fetch(url, {
			method: "POST",
			headers: {
				'Content-Type': "application/json"
			},
			body: JSON.stringify(data)
		});
		if (response.status != 200) {
			throw new Error("Server responded with unexpected HTTP status.");
		}

		return true;
	}
}

export type CountDownloadRequest = {
	type: DownloadFileLinkType,
	uuid: string
};

export default API;

import * as React from 'react';
import AgreementVersion from '../types/AgreementVersion';
import Agreement from './AgreementListBlock';

interface AgreementListProps {
	agreements: AgreementVersion[]
}
interface AgreementListState {}

class AgreementList extends React.Component<AgreementListProps, AgreementListState> {
	render() {
		return (
			<section className="mb-5 ffnext-agreement-list">
				{this.props.agreements.map((version) => (
					<React.Fragment key={version.id}>
						<Agreement agreement={version}/>
						<hr/>
					</React.Fragment>
				))}
			</section>
		);
	}
}

export default AgreementList;

const $ = require('jquery');
$(document).ready(function () {
	const $create = $('.branch-create');
	const $branchTableBody = $('.branch-table-body');
	const $modal = $('#confirm-delete-branch-modal');
	const $modalConfirm = $('#confirm-delete-branch-modal .branch-delete-confirm');
	const $modalTitle = $('#confirm-delete-branch-modal .branch-delete-title');
	const $modalBody = $('#confirm-delete-branch-modal .branch-delete-body');

	// Create - add new row to table
	$create.on('click', function () {
		$(`
			<tr>
				<td>
					<input type="text" class="form-control branch-input pl-2 pr-2">
				</td>
				<td>
					-
				</td>
				<td>
					-
				</td>
				<td class="text-center">
					<button class="btn branch-create-confirm btn-sm ml-1" type="submit"><i class="fa fa-check"></i></button>
					<button class="btn branch-create-cancel btn-sm" type="button"><i class="fa fa-times"></i></button>
				</td>
			</tr>
		`).prependTo($branchTableBody);
	});

	// Create - confirm
	$branchTableBody.on('click', '.branch-create-confirm', function () {
		const row = getRow(this);
		const title = row.find('.branch-input').val();
		$.ajax({
			url: '/api/branch',
			cache: false,
			contentType: 'application/json',
			processData: false,
			type: 'POST',
			data: JSON.stringify({title}),
			success: () => {
				location.reload();
			},
			error: () => {
				row.remove();
			}
		});
	});

	// Create - cancel
	$branchTableBody.on('click', '.branch-create-cancel', function () {
		getRow(this).remove();
	});


	// Update start
	$branchTableBody.on('click', '.branch-edit', function () {
		const row = getRow(this);
		// set input value to branch title
		row.find('.branch-input').val(row.data('title'));
		updateToggleVisibility(this);
	});

	// Update cancel
	$branchTableBody.on('click', '.branch-edit-cancel', function () {
		updateToggleVisibility(this);
	});

	// Update confirm
	$branchTableBody.on('click', '.branch-edit-confirm', function () {
		const row = getRow(this);
		$.ajax({
			url: `/api/branch/${row.data('id')}`,
			cache: false,
			contentType: 'application/json',
			processData: false,
			type: 'PUT',
			data: JSON.stringify({title: row.find('.branch-input').val()}),
			success: function () {
				location.reload();
			},
			error: function () {
				updateToggleVisibility(this);
			}
		});
	});


	// Remove start
	$branchTableBody.on('click', '.branch-delete', function () {
		const row = getRow(this);
		$modalConfirm.data('branch', row.data('id'));
		$modalTitle.text(row.data('title'));

		const numRelatedAgreements = parseInt(row.data('total'), 10);
		if (!numRelatedAgreements) {
			$modalBody.empty();
			$modalBody.append($(`
				<p>
					Er du helt sikker på at du vil slette denne
					sammenslutningen? Denne handlingen kan ikke angres!
				</p>
			`));
		} else if (numRelatedAgreements === 1) {
			$modalBody.empty();
			$modalBody.append($(`
				<p>
					Å slette denne sammenslutningen betyr at du også vil slette
					<strong>1</strong> tilhørende avtale. Er du helt sikker på
					at du vil fortsette? Denne handlingen kan ikke angres!
				</p>
			`));
		} else {
			$modalBody.empty();
			$modalBody.append($(`
				<p>
					Å slette denne sammenslutningen betyr at du også vil slette
					<strong>${numRelatedAgreements}</strong> tilhørende avtaler. Er du helt
					sikker på at du vil fortsette? Denne handlingen kan ikke
					angres!
				</p>
			`));
		}
	});

	// Remove confirm
	$('.branch-delete-confirm').on('click', function () {
		const deletedBranchID = $modalConfirm.data('branch');
		$.ajax({
			url: `/api/branch/${deletedBranchID}`,
			cache: false,
			processData: false,
			type: 'DELETE',
			success: function () {
				location.reload();
			},
			error: function () {
				$modal.hide();
			}
		});
	});


	const updateToggleVisibility = (controlButton) => {
		// toggle visibility of controls
		$(controlButton).parent().children().toggleClass('d-none');
		// toggle visibility of title/input
		getRow(controlButton).find('.branch-input').parent().children().toggleClass('d-none');
	};

	const getRow = (target) => $(target).closest('tr');
});
